import React, {useState, useEffect, forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import CustomInputDate from './CustomInputDate';
import Toggle from '../Toggles/Toggle';
import {join} from '../../Helpers/formatData';
// import mmlLogo from '../../Icons/mml-logo.png';
// import ScannerLogo from '../../Icons/scanner_image.png';

const moment = require('moment');

const ScannerHeader = ({
  name,
  theme,
  setTheme,
  userData,
  showChart,
  groupCollection,
  dispatch,
  showSelector,
  groups,
  wsSocket,
  currentDate,
  updatingDate,
  setUpdatingDate,
}) => {
  // const freeAccess = userData?.free_access;
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (groupCollection) {
      const group = Object.values(groupCollection)[0];
      if (group) {
        setSelectedDate(group?.selectedDate);
      }
    }
  }, [groupCollection]);

  const disableDays = (current) => {
    const june = moment().set({year: 2022, month: 4, date: 31}).toDate();
    const day = moment(current).day();
    return day !== 0 && day !== 6 && current >= june;
  };

  const formatDate = (date) => {
    const timestamp = Date.parse(date);
    if (Number.isNaN(timestamp)) return new Date();
    const selectedToMomentDay = moment(date, 'YYYY-MM-DD');
    const june = moment().set({year: 2022, month: 4, date: 31});
    const day = selectedToMomentDay.day();
    // If not weekend and after June 31st, return date
    if (day !== 0 && day !== 6 && !moment(selectedToMomentDay).isBefore(june)) {
      return selectedToMomentDay.toDate();
    }
    return new Date();
  };

  const handleMultiGroupDatePickerChange = (newDate) => {
    if (!groups?.length || !newDate) return;
    groups.forEach((gr) => {
      const {type: groupType, group: groupName} = gr;
      if (!groupType || !groupName) return;

      const scannerType = groupType ? decodeURIComponent(groupType) : 'tickalert';
      const groupToJoin = {
        group: `${groupName ? decodeURIComponent(groupName) : ''}`,
        date: newDate,
      };
      wsSocket?.emit(`${scannerType}:join`, groupToJoin);
    });
    dispatch({type: 'CHANGE_MULTI_GROUP_DATE', payload: {newDate, groupCollection}});
  };

  const handleDatePickerChange = (date) => {
    if (!wsSocket) return;
    const newDate = moment(date).format('YYYY-MM-DD');
    if (setUpdatingDate) {
      setUpdatingDate(true);
    }
    // changeTab(0);
    handleMultiGroupDatePickerChange(newDate);
  };

  useEffect(() => {
    if (!Object.keys(groupCollection ?? {}).length || !updatingDate) return;
    const groupCollectionKeys = Object.keys(groupCollection ?? {});
    const filteredKeys = groupCollectionKeys.filter((key) => {
      const gr = groupCollection[key];
      if (!gr) {
        return false;
      }
      if (!gr?.currentDate) {
        if (!gr?.sentDate) {
          console.log('no sent date', gr?.sentDate);
          return false;
        }
        if (gr?.sentDate && !gr?.recievedDate) {
          console.log('no recieved date', gr?.recievedDate);
          return false;
        }
        if (gr?.sentDate && gr?.recievedDate) {
          console.log('sent date', gr?.sentDate, 'recieved date', gr?.recievedDate);
          return gr?.sentDate !== gr?.recievedDate;
        }
      }
      return false;
    });
    if (!filteredKeys.length) {
      setUpdatingDate(false);
    }
  }, [groups, groupCollection]);

  return (
    <div className={`${showChart ? 'chart-visible' : ''} mx-auto scanner-group-header`}>
      <div className="flex justify-between">
        <div className="flex gap-4 items-center">
          {showSelector ? (
            <>
              {/* <img className="w-8 h-8" src={ScannerLogo} alt="Logo" /> */}
              {/* <DarkPoolIcon /> */}
              <h1 className="text-header-color font-bold text-[24px] leading-[28px] ml-2 group-name">{name}</h1>
              <div>
                {updatingDate ? (
                  <div className="lds-ring-date">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                ) : (
                  <div className="flex items-center">
                    <DatePicker
                      filterDate={disableDays}
                      selected={currentDate ? formatDate(currentDate) : formatDate(selectedDate ?? null)}
                      onChange={handleDatePickerChange}
                      customInput={<CustomInputDate />}
                    />
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
        <div className="flex gap-7 pr-5 dark-mode-toggle">
          <div className="flex items-center">
            <Toggle theme={theme} setTheme={setTheme} />
          </div>
        </div>
      </div>
      {/* <div className="mt-2">
        <small>To change scanner alert subscriptions, pleace go to <u><a href="/" target="_blank" rel="noreferrer"> Alert Settings</a></u>.</small>
      </div> */}
    </div>
  );
};

export default ScannerHeader;
