/* eslint-disable no-unreachable */
import React, {useEffect, useState} from 'react';
import {useOutletContext} from 'react-router-dom';
import Modal from 'react-modal';
import 'react-tabs/style/react-tabs.css';
// import SpyderIcon from '../Icons/spyder_icon.png';
// import GraphemIcon from '../Icons/graphem-logo.png';
import Default from '../ScannerViews/Default';
import Grid from '../ScannerViews/Grid';
import TabsView from '../ScannerViews/Tabs';
import MultiLayout from '../ScannerViews/MultiLayout';

Modal.setAppElement('#scan_scanner');

const layoutOptions = {
  tabs: 'tabs-layout',
  default: 'default-layout',
  grid: 'grid-layout',
  verticalStack: 'vertical-stack-layout',
  horizontalStack: 'horizontal-stack-layout',
};

const Scanners = ({theme, setTheme}) => {
  const [
    {
      scannersData,
      userData,
      groupCollection,
      groups,
      loadingGroups,
      chartSettings,
      showChart,
      symbolData,
      symbolToChart,
      groupToChart,
      secondaryGroups,
      optionToView,
      reportToView,
      reports,
      inView,
      currentDate,
      ...state
    },
    dispatch,
    wsSocket,
  ] = useOutletContext();

  const [modalShow, setModalShow] = useState(false);
  const [multiLayoutConfig, setMultiLayoutConfig] = useState({});
  const [scannerLayout, setScannerLayout] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showLockedModal, setShowLockedModal] = useState(false);
  const [showUpcomingModal, setShowUpcomingModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  useEffect(() => {
    if (scannersData?.length && !scannerLayout) {
      const urlPath = window.location.pathname.split('/');
      if (!urlPath[2]) {
        return;
      }
      const scannerData = scannersData?.find((s) => s.slug === urlPath[2]);
      const {layout, multiLayout} = scannerData ?? {layout: 'default-layout'};
      if (layout) {
        Object.values(layoutOptions).forEach((option) =>
          document.getElementsByTagName('body')[0].classList.toggle(option, layout === option),
        );
        setScannerLayout(layout);
      }
      // View has multiple layouts under tabs
      if (multiLayout) {
        const {type} = multiLayout;
        setScannerLayout('multi-layout');
        setMultiLayoutConfig(multiLayout);
      }
    }
  }, [scannersData]);

  const handleClose = () => setModalShow(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const getSymbolData = (symbol, gr) => {
    const hasListedExchange = groupCollection[gr.group]?.dataTypes?.find((dt) => dt?.key === 'ListedExchange');
    if (!hasListedExchange) return;
    dispatch({type: 'SET_SYMBOL_TO_CHART', payload: {symbol, group: gr.group}});
    setTimeout(() => {
      openModal();
    }, 1000);

    // if (wsSocket) {
    //   wsSocket.emit('chart:get', {symbol, group: gr.group});
    //   dispatch({type: 'SET_SYMBOL_TO_CHART', payload: {symbol, group: gr.group}});
    // }
  };

  const closeChart = () => {
    dispatch({type: 'CLOSE_CHART'});
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openOptionsModal = (row, group) => {
    if (!row || !row?.Symbol?.value) return;
    dispatch({type: 'SET_OPTION_TO_VIEW', payload: {symbol: row?.Symbol?.value, group: group?.group}});
    setShowOptionsModal(true);
  };
  const closeOptionsModal = () => {
    setShowOptionsModal(false);
  };

  const openReportModal = (row, group, useSummary = false) => {
    if ((!row || !row?.Symbol?.value) && !useSummary) return;
    let payload = {symbol: row?.Symbol?.value, group: group?.group, useSummary};
    if (useSummary) {
      payload = {group: group?.group, symbol: null, useSummary};
    }
    dispatch({type: 'SET_REPORT_TO_VIEW', payload});
    setShowReportModal(true);
  };
  const closeReportModal = () => {
    setShowReportModal(false);
  };

  const openLockedModal = () => {
    setShowLockedModal(true);
  };

  const closeLockedModal = () => {
    setShowLockedModal(false);
  };

  const openUpcomingModal = () => {
    setShowUpcomingModal(true);
  };

  const closeUpcomingModal = () => {
    setShowUpcomingModal(false);
  };

  const hasMainReport = (grp) => {
    if (!grp) {
      return false;
    }

    const report = grp?.report?.mainReport;
    if (!report) {
      return false;
    }
    const repCollection = groupCollection?.[report];
    if (!repCollection) {
      return false;
    }
    if (!repCollection?.rawData?.length) {
      return false;
    }
    return Object.keys(repCollection?.rawData[0] ?? {}).length;
  };

  if (groupCollection && Object.keys(groupCollection ?? {})?.length && groups?.length && !loadingGroups) {
    if (scannerLayout === 'multi-layout') {
      return (
        <MultiLayout
        layoutConfig={multiLayoutConfig}
        scannersData={scannersData}
        groupCollection={groupCollection}
        groups={groups}
        dispatch={dispatch}
        theme={theme}
        setTheme={setTheme}
        wsSocket={wsSocket}
        loadingGroups={loadingGroups}
        modalIsOpen={modalIsOpen}
        userData={userData}
        showChart={showChart}
        closeChart={closeChart}
        symbolData={symbolData}
        chartSettings={chartSettings}
        getSymbolData={getSymbolData}
        symbolToChart={symbolToChart}
        closeModal={closeModal}
        showOptionsModal={showOptionsModal}
        openOptionsModal={openOptionsModal}
        closeOptionsModal={closeOptionsModal}
        showReportModal={showReportModal}
        openReportModal={openReportModal}
        closeReportModal={closeReportModal}
        optionToView={optionToView}
        reportToView={reportToView}
        groupToChart={groupToChart}
        showLockedModal={showLockedModal}
        openLockedModal={openLockedModal}
        closeLockedModal={closeLockedModal}
        showUpcomingModal={showUpcomingModal}
        openUpcomingModal={openUpcomingModal}
        closeUpcomingModal={closeUpcomingModal}
        modalShow={modalShow}
        handleClose={handleClose}
        scannerLayout={scannerLayout}
        secondaryGroups={secondaryGroups}
        reports={reports}
        currentDate={currentDate}
        hasMainReport={hasMainReport}
        />
      );
    }
    if (scannerLayout === 'tabs-layout') {
      return (
        <TabsView
          scannersData={scannersData}
          groupCollection={groupCollection}
          groups={groups}
          dispatch={dispatch}
          theme={theme}
          setTheme={setTheme}
          wsSocket={wsSocket}
          loadingGroups={loadingGroups}
          modalIsOpen={modalIsOpen}
          userData={userData}
          showChart={showChart}
          closeChart={closeChart}
          symbolData={symbolData}
          chartSettings={chartSettings}
          getSymbolData={getSymbolData}
          symbolToChart={symbolToChart}
          closeModal={closeModal}
          showOptionsModal={showOptionsModal}
          openOptionsModal={openOptionsModal}
          closeOptionsModal={closeOptionsModal}
          showReportModal={showReportModal}
          openReportModal={openReportModal}
          closeReportModal={closeReportModal}
          optionToView={optionToView}
          reportToView={reportToView}
          groupToChart={groupToChart}
          showLockedModal={showLockedModal}
          openLockedModal={openLockedModal}
          closeLockedModal={closeLockedModal}
          showUpcomingModal={showUpcomingModal}
          openUpcomingModal={openUpcomingModal}
          closeUpcomingModal={closeUpcomingModal}
          scannerLayout={scannerLayout}
          hasMainReport={hasMainReport}
          reports={reports}
          inView={inView}
          currentDate={currentDate}
        />
      );
    }
    if (scannerLayout === 'grid-layout') {
      return (
        <Grid
          scannersData={scannersData}
          groupCollection={groupCollection}
          groups={groups}
          dispatch={dispatch}
          theme={theme}
          setTheme={setTheme}
          wsSocket={wsSocket}
          loadingGroups={loadingGroups}
          modalIsOpen={modalIsOpen}
          userData={userData}
          showChart={showChart}
          closeChart={closeChart}
          symbolData={symbolData}
          chartSettings={chartSettings}
          getSymbolData={getSymbolData}
          symbolToChart={symbolToChart}
          closeModal={closeModal}
          showOptionsModal={showOptionsModal}
          openOptionsModal={openOptionsModal}
          closeOptionsModal={closeOptionsModal}
          showReportModal={showReportModal}
          openReportModal={openReportModal}
          closeReportModal={closeReportModal}
          optionToView={optionToView}
          reportToView={reportToView}
          groupToChart={groupToChart}
          showLockedModal={showLockedModal}
          openLockedModal={openLockedModal}
          closeLockedModal={closeLockedModal}
          showUpcomingModal={showUpcomingModal}
          openUpcomingModal={openUpcomingModal}
          closeUpcomingModal={closeUpcomingModal}
          modalShow={modalShow}
          handleClose={handleClose}
          scannerLayout={scannerLayout}
          secondaryGroups={secondaryGroups}
          reports={reports}
        />
      );
    }

    return (
      <Default
        scannersData={scannersData}
        groupCollection={groupCollection}
        groups={groups}
        dispatch={dispatch}
        theme={theme}
        setTheme={setTheme}
        wsSocket={wsSocket}
        loadingGroups={loadingGroups}
        modalIsOpen={modalIsOpen}
        userData={userData}
        showChart={showChart}
        closeChart={closeChart}
        symbolData={symbolData}
        chartSettings={chartSettings}
        getSymbolData={getSymbolData}
        symbolToChart={symbolToChart}
        closeModal={closeModal}
        showOptionsModal={showOptionsModal}
        openOptionsModal={openOptionsModal}
        closeOptionsModal={closeOptionsModal}
        showReportModal={showReportModal}
        openReportModal={openReportModal}
        closeReportModal={closeReportModal}
        optionToView={optionToView}
        reportToView={reportToView}
        groupToChart={groupToChart}
        showLockedModal={showLockedModal}
        openLockedModal={openLockedModal}
        closeLockedModal={closeLockedModal}
        showUpcomingModal={showUpcomingModal}
        openUpcomingModal={openUpcomingModal}
        closeUpcomingModal={closeUpcomingModal}
        modalShow={modalShow}
        handleClose={handleClose}
        scannerLayout={scannerLayout}
        secondaryGroups={secondaryGroups}
        reports={reports}
        currentDate={currentDate}
      />
    );
  }

  if (!groups || !groups?.length) {
    return (
      <div id="loading-user" className="flex w-full h-full fixed justify-center items-center flex-wrap">
        <div className="loading-user-wrapper">
          <span>No Scanners Available</span>
        </div>
      </div>
    );
  }

  return (
    <div id="loading-user" className="flex w-full h-full fixed justify-center items-center flex-wrap">
      <div className="loading-user-wrapper">
        <div className="mb-2 text-center">
          <div className="loader" />
        </div>
      </div>
    </div>
  );
};

// Scanners.whyDidYouRender = true;
export default React.memo(Scanners, (prev, next) => {
  const stateSame = JSON.stringify(prev?.state) === JSON.stringify(next?.state);
  return stateSame;
});
// export default Scanners;
