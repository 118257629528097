import React, {useState, useEffect} from 'react';

const NumAbbr = require('number-abbreviate');


const hasNumbers = (t) => {
  const regex = /\d/g;
  return regex.test(t);
};

const ReportView = ({reportToView, groupCollection, showSecondaryHeader}) => {
  const [reportData, setReportData] = useState(null);
  const [reportTitle, setReportTitle] = useState(null);
  const [reportDataTypes, setReportDataTypes] = useState(null);
  const [reportOrderTypes, setReportOrderTypes] = useState(null);

  useEffect(() => {
    if (!reportToView || !groupCollection) {
      if (reportData) setReportData(null);
      return;
    }
    if (reportToView && Object.keys(reportToView ?? {}).length) {
      const {group, symbol, useSummary} = reportToView;
      const formattedSymbol = hasNumbers(symbol) ? symbol.split(/[0-9]/)[0] : symbol;

      const collectionData = groupCollection[group];
      const report = useSummary ? collectionData?.report?.mainReport : collectionData?.report?.symbolReport;
      if (!report) {
        setReportData(null);
        return;
      }
      const repCollection = groupCollection?.[report];
      if (!repCollection) {
        return;
      }
      const {groupTitle, orderTypes, dataTypes, data: repData, rawData} = repCollection;
      setReportTitle(groupTitle);
      setReportDataTypes(dataTypes);
      setReportOrderTypes(orderTypes);
      if (rawData?.length && !useSummary) {
        const data = rawData.find((d) => d.Symbol?.value === formattedSymbol);
        if (data) {
          setReportData(data);
        }
      }
      if (useSummary && rawData?.length) {
        if (Object.keys(rawData[0] ?? {})?.length) {
          setReportData(rawData[0]);
        }
      }
    }
  }, [reportToView, groupCollection]);

  if (reportToView && !reportData) {
    return (
      <div>
        <div className="flex items-start justify-between flex-col gap-2">
          <div className="power-options">
            <h1>{reportTitle ?? ''}</h1>
          </div>
          <h2 className="text-xl font-bold">{reportData?.Symbol?.value}</h2>
        </div>
        <div>
          <div className="my-4 text-center">
            <div className="loader" />
          </div>
        </div>
      </div>
    );
  }

  const displayValue = ({data, dataType}) => {
    const {display} = dataType;
    const {value} = data;
    const formatPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    switch (display) {
      case 'percentage':
        return value && !Number.isNaN(Number(value)) ? <span>{`${value}%`}</span> : null;
      case 'money-short':
        return value && !Number.isNaN(Number(value)) ? (
          <span>{`$${NumAbbr(Number(value), 2)}`.toUpperCase()}</span>
        ) : null;
      case 'number-short':
        return value && !Number.isNaN(Number(value)) ? (
          <span>{`${NumAbbr(Number(value), 2)}`.toUpperCase()}</span>
        ) : null;
      case 'money':
        return value && !Number.isNaN(Number(value)) ? <span>{formatPrice.format(Number(value))}</span> : null;
      default:
        return value && !Number.isNaN(Number(value)) ? <span>{value}</span> : null;
    }
  };

  return reportData ? (
    <div>
      <div className="flex items-start justify-between flex-col gap-2">
        <div className="power-options">
          <h2 className="text-[28px] -mb-1 font-bold">{reportData?.Symbol?.value}</h2>
          <h1>{reportTitle ?? ''}</h1>
        </div>
      </div>
      <div>
        <div className=" flow-root">
          <div className="-my-2 overflow-x-hidden ">
            <div className="inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">
                      <span />
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold ">
                      <span />
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {reportDataTypes?.length
                    ? reportDataTypes?.map((type, index) => {
                        const value = reportData[type?.key];
                        return (
                          <tr key={type?.key}>
                            <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-[#b2b6ce]">
                              {type?.title}
                            </td>
                            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300">
                              {displayValue({data: value, dataType: type})}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const optionValuesSame = (optionsLayout, prev, next) => {
  const optionsLayoutValues = Object.values(optionsLayout ?? {});
  if (!optionsLayoutValues?.length) {
    return true;
  }
  const {groupCollection: prevGroupCollection, reportToView: prevreportToView} = prev ?? {
    groupCollection: null,
    reportToView: null,
  };
  const {groupCollection: nextGroupCollection, reportToView: nextreportToView} = next ?? {
    groupCollection: null,
    reportToView: null,
  };
  const {group: prevGroup, symbol: prevSymbol} = prevreportToView ?? {group: null, symbol: null};
  const {group: nextGroup, symbol: nextSymbol} = nextreportToView ?? {group: null, symbol: null};
  const {data: prevData} = prevGroupCollection?.[prevGroup] ?? {data: null};
  const {data: nextData} = nextGroupCollection?.[nextGroup] ?? {data: null};
  if (!prevData && !nextData) {
    return true;
  }
  const prevSymbolData = prevData?.[prevSymbol];
  const nextSymbolData = nextData?.[nextSymbol];

  if (JSON.stringify(prevSymbolData) !== JSON.stringify(nextSymbolData)) {
    const areSame = optionsLayoutValues.every((l) => {
      const {keys} = l;
      return keys.every(
        (k) => JSON.stringify(prevSymbolData?.[k]?.value) === JSON.stringify(nextSymbolData?.[k]?.value),
      );
    });
    return areSame;
  }
  return true;
};

export default React.memo(ReportView, (prev, next) => {
  const reportToViewSame =
    prev.reportToView?.group === next.reportToView?.group && prev.reportToView?.symbol === next.reportToView?.symbol;
  const optionsLayoutSame = JSON.stringify(prev.optionsLayout) === JSON.stringify(next.optionsLayout);
  const showSecondaryHeaderSame = prev.showSecondaryHeader === next.showSecondaryHeader;
  return (
    optionsLayoutSame &&
    reportToViewSame &&
    optionValuesSame(next?.optionsLayout, prev, next) &&
    showSecondaryHeaderSame
  );
});

// export default ReportView;
